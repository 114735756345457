import React, {useEffect} from 'react';
import './App.css';
import OffersPage from "./components/offersPage/offersPage";

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import SearchPage from "./components/searchPage/searchPage";
import DetailPage from "./components/offersPage/carousel/detailsPage/detailsPage";

import HotelAmenitiesPage from "./components/offersPage/carousel/detailsPage/details/HotelAmenitiesPage";

const YOUR_HOTJAR_ID = '5161776';
const App = () => {

    useEffect(() => {
        // Hotjar tracking script
        (function (h, o, t, j, a, r) {
            h.hj = h.hj || function () {
                (h.hj.q = h.hj.q || []).push(arguments)
            };
            h._hjSettings = {hjid: YOUR_HOTJAR_ID, hjsv: 6};
            a = o.getElementsByTagName('head')[0];
            r = o.createElement('script');
            r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }, []); // Empty dependency array ensures this runs once when component mounts

    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/" exact element={<SearchPage/>}/>
                    <Route path="/offers" element={<OffersPage/>}/>
                    <Route path="/search" element={<SearchPage/>}/>
                    <Route path="/detail/:id" element={<DetailPage/>}/>
                    <Route path="/details/amenities" element={<HotelAmenitiesPage/>}/>
                </Routes>
            </Router>
        </div>
    );
}

export default App;

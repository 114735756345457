import React, {useEffect, useState} from 'react';
import {useParams, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {updateOfferSelection} from '../../../../store/actions/offerActions';

import FlightOfferDetails from './details/FlightOffer';
import '../expandableItem/styles.css';
import LeftSelectionButton from './buttons/leftSelectionButton';
import CentralSelectionButton from './buttons/centralSelectionButton';
import RightSelectionButton from './buttons/rightSelectionButton';
import DetailsNavigation from '../../../footer/detailsNavigation';
import HotelOfferDetails from "./details/HotelOffer";
import TransferOfferDetails from "./details/TransferOffer";
import HotelAmenitiesPage from "./details/HotelAmenitiesPage";

const DetailPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const {myListData} = location.state || {}; // Extract myListData from the location state

    const item = myListData ? myListData.find(item => item.id === Number(id)) : null;

    const dispatch = useDispatch();

    const currentOfferId = useSelector((state) => state.offer.currentOffer);
    const selectedOptionIndexFromRedux = useSelector((state) => {
        const offerDetails = state.offer.selectedOffers[currentOfferId] || {};
        return offerDetails[item.id] || 0;
    });


    const [selectedOptionIndex, setSelectedOptionIndex] = useState(selectedOptionIndexFromRedux);

    useEffect(() => {
        setSelectedOptionIndex(selectedOptionIndexFromRedux);
    }, [selectedOptionIndexFromRedux]);

    // Returns to the previous page
    const handleBackClick = () => {
        navigate(-1);
    };

    const handleOptionClick = (index) => {
        setSelectedOptionIndex(index);
        dispatch(updateOfferSelection(currentOfferId, item.id, index));
        console.log("selected option: ", index)
    };

    if (!item) {
        return <div>Item not found</div>;
    }


    const renderHotelDetails = (details, index) => {
        const hotelDetails = details[index];
        return <HotelOfferDetails details={hotelDetails}/>;
    };

       const renderHotelAmenities = (details, index) => {
        const hotelDetails = details[index];
        return <HotelAmenitiesPage details={hotelDetails}/>;
    };

    const renderOptions = (selectionBox) => {
        return Object.keys(selectionBox).map((key, index) => {
            const option = selectionBox[key];
            const isSelected = index === selectedOptionIndex;

            switch (index) {
                case 0:
                    return (
                        <LeftSelectionButton
                            key={key}
                            price={option.price}
                            onClick={() => handleOptionClick(index)}
                            isSelected={isSelected}
                        />
                    );
                case 1:
                    return (
                        <CentralSelectionButton
                            key={key}
                            price={option.price}
                            onClick={() => handleOptionClick(index)}
                            isSelected={isSelected}
                        />
                    );
                case 2:
                    return (
                        <RightSelectionButton
                            key={key}
                            price={option.price}
                            onClick={() => handleOptionClick(index)}
                            isSelected={isSelected}
                        />
                    );
                default:
                    return null;
            }
        });
    };

    const renderDetails = (details, index) => {
        if (item.id === 1 || item.id === 5) {
            const flightDetails = details[index];
            return <FlightOfferDetails details={flightDetails}/>;
        } else if (item.id === 2 || item.id === 4) {
            const detail = details[index];
            return <TransferOfferDetails details={detail}/>

            // return (
            //     <div className={`details ${index === selectedOptionIndex ? 'visible' : ''}`}>
            //         <div className="vehicle-image-container">
            //             <img src={detail.vehicle.imageURL} alt="vehicle" className="vehicle-image" />
            //         </div>
            //         <div className="vehicle-details">
            //             <div className="option-time">Pick-up time: {pickupTime}</div>
            //             <div className="vehicle-info">Vehicle type: {detail.vehicle.code}</div>
            //             <div className="vehicle-info">Seats: {detail.vehicle.seats[0].count}</div>
            //         </div>
            //     </div>
            // );
        }
        return <p>{item.info1}</p>;
    };

    return (

        <div className="details-wrapper">
            {/*<Header />*/}
            <div className="overlay-content">
                {(item.id === 1 || item.id === 5 || item.id === 2 || item.id === 4) ? (
                    <>
                        {renderDetails(item.info1.details, selectedOptionIndex)}
                        <div className="selection-options">{renderOptions(item.info1['selection-box'])}</div>
                    </>
                ) : (
                    <>
                        {renderHotelDetails(item.info1.details, selectedOptionIndex)}
                        {console.log(item.info1.details)}
                        <div className="selection-options">{renderOptions(item.info1['selection-box'])}</div>
                        {renderHotelAmenities(item.info1.details, selectedOptionIndex)}

                    </>
                )}
            </div>
            <DetailsNavigation onClick={handleBackClick} />
        </div>
    );
};

export default DetailPage;

// actions/offerActions.js

export const SELECT_CURRENT_OFFER = 'SELECT_CURRENT_OFFER';
export const UPDATE_OFFER_SELECTION = 'UPDATE_OFFER_SELECTION';
export const RESET_OFFERS = 'RESET_OFFERS';


export const selectCurrentOffer = (offerId) => ({
    type: SELECT_CURRENT_OFFER,
    payload: offerId,
});

export const updateOfferSelection = (offerId, itemId, optionId) => ({
    type: UPDATE_OFFER_SELECTION,
    payload: {offerId, itemId, optionId},
});

export const resetOffers = () => ({
    type: RESET_OFFERS,
});

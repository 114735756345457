import React from 'react';
import './styles.css';
import SearchForm from "../tripOrganisation/searchForm";

const SearchPage = () => {
    return (
        <div>
            <SearchForm/>
        </div>
    )
        ;
}

export default SearchPage;
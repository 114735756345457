import React, {useState} from "react";
import "./styles.css";

const CityButton = ({onClick, city, disabled}) => {
    const [isPressed, setIsPressed] = useState(false);

    const handleClick = () => {
        if (!disabled) {
            setIsPressed(!isPressed);
            onClick();
        }
    };

    return (
        <div
            className={`button-country-outer rounded-left-button ${isPressed ? "pressed" : ""}`}
            onClick={handleClick}
        >
            <div className={`button-country-inner rounded-left-button ${isPressed ? "translate" : ""}`}>
                <p className={`country-city-letters ${isPressed ? "translate" : ""}`}>{city}</p>
            </div>
        </div>
    );
};

export default CityButton;

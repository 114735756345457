import React, {useState} from "react";
import "./styles.css";
import {ReactComponent as InfoIcon} from "../../../images/icons/info.svg";

const CountryInfoButton = ({ onClick}) => {
      const [isPressed, setIsPressed] = useState(false);

    const handleClick = () => {
        setIsPressed(true);
        setTimeout(() => {
            setIsPressed(false);
        }, 300);
        onClick();
    };

  return (
    <div
      className={`button-info-outer rounded-right-button ${isPressed ? "pressed" : ""}`}
      onClick={handleClick}
    >
      <div className={`button-info-inner rounded-right-button ${isPressed ? "translate" : ""}`}>
          <InfoIcon style={{ margin: "auto" }} />
      </div>
    </div>
  );
};

export default CountryInfoButton;
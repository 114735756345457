import React from "react";
import "../styles.css";

const LeftSelectionButton = ({ isSelected, onClick, price }) => {

    return (
        <div
            className={`selection-option left ${isSelected ? "selected" : ""}`}
            onClick={onClick}
        >
            <div className={`selection-option-inner left ${isSelected ? "translate" : ""}`}>
                {price}
            </div>
        </div>
    );
};

export default LeftSelectionButton;

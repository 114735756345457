import React from 'react';
import './styles.css';
import NextButtonLarge from "../tripOrganisation/buttons/nextButtonLarge";

const NavigationInitial = ({
                        onNextClick,
                        nextDisabled = false,

                    }) => {
    return (
        <div className="navigation-wrapper">
            <div className="navigation">
                {
                    <NextButtonLarge onClick={onNextClick} disabled={nextDisabled}/>
                }
            </div>
        </div>
    );
};

export default NavigationInitial;

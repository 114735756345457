import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import InitialDataStep from './steps/initialDataEntry/initialDataStep';
import BudgetSmartphoneStep from './steps/setBudget/budgetSmartphoneStep';
import CitiesPickStep from './steps/chooseCities/cityPickStep';
import PreferredLocationsStep from './steps/chooseLocations/preferredLocationStep';
import TravelTypeStep from './steps/setActivityTag/typeOfTravelStep';
import PostRequest from '../api/postRequest';
import GetRequest from '../api/getRequest';
import Cookies from 'js-cookie';
import LoadingSpinner from "../utils/loadingSpinner/loadingSpinner";
import {useDispatch} from "react-redux";
import {resetOffers} from "../../store/actions/offerActions";
import {resetTransferFetched} from "../../store/actions/transferActions";
import SearchAgainButton from "./buttons/searchAgainButton";


const SearchForm = () => {
    const [step, setStep] = useState(1);
    const [idealDestination, setIdealDestination] = useState(true);
    const [rangeNotSelectedError, setRangeNotSelectedError] = useState(false);
    const [formData, setFormData] = useState({
        departureLocation: '',
        destination: '',
        adults: 0,
        children: 0,
    });
    const [dateRange, setDateRange] = useState({
        departureDate: null,
        returnDate: null,
    });
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedTagsStep3, setSelectedTagsStep3] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [fetchError, setFetchError] = useState(null);

    const RANGE_NOT_SELECTED_ERROR = 'Please select both start and end dates.';

    const calendarPlaceholder = 'select dates';
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // Fetch offers from API
    const fetchOffers = async () => {
        setIsLoading(true);
        try {
            const getOffers = GetRequest('api/get_offers/');
            const data = await getOffers();
            if (data.message) {
                const formattedOffers = Object.keys(data.message).map((offerId) => ({
                    offerId,
                    offers: data.message[offerId]
                }));
                return formattedOffers;
            } else {
                throw new Error('Failed to load offers');
            }
        } catch (error) {
            console.error("Error fetching offers:", error);
            setFetchError(error.message);
            return null;
        } finally {
            setIsLoading(false);
        }
    };


    // UseEffect to automatically fetch offers when step 6 is reached

    useEffect(() => {
        if (step === 6) {
            // Reset offers before fetching new ones
            dispatch(resetOffers());
            dispatch(resetTransferFetched());
            const fetchData = async () => {
                const fetchedOffers = await fetchOffers();
                if (fetchedOffers) {
                    console.log('Offers fetched successfully.');

                    navigate('/offers', {
                        state: {
                            offers: fetchedOffers,
                            isLoading: false
                        }
                    });
                } else {
                    <SearchAgainButton reload={true}/>
                    console.error('Failed to fetch offers after polling.');
                    setFetchError('Failed to fetch offers. Please try again later.');

                }
            };
            fetchData();
        }
    }, [step, navigate, dispatch]);

    // Handle step transition
    const nextStep = async (tags) => {

        if (step === 1) {
            if (!(dateRange.departureDate && dateRange.returnDate && dateRange.departureDate !== dateRange.returnDate)) {
                setRangeNotSelectedError(RANGE_NOT_SELECTED_ERROR);
                return;
            } else {
                await sendApiInitialData();
            }
        }

        if (step === 3) {
            setSelectedTagsStep3(tags);
        }

        if (step === 4) {
            setSelectedCities(tags);
        }

        if (step === 5) {
        }

        setStep((prevStep) => prevStep + 1);
    };

    const prevStep = () => {
        setStep((prevStep) => prevStep - 1);
    };

    const formatDateToLocal = (date) => {
        if (!date) return null;
        const offset = date.getTimezoneOffset();
        date = new Date(date.getTime() - offset * 60 * 1000);
        return date.toISOString().split('T')[0];
    };

    const sendApiInitialData = async () => {

        const requestParams = JSON.stringify({
            ...formData,
            departureDate: dateRange.departureDate ? formatDateToLocal(dateRange.departureDate) : null,
            returnDate: dateRange.returnDate ? formatDateToLocal(dateRange.returnDate) : null,
        })
        const requestData = {
            message: JSON.parse(
                requestParams
            ),
        };
        const path = 'api/start/';

        const data = await PostRequest(requestData, path, 'json');

        if (data && data.task_id) {
            const isHttps = window.location.protocol === 'https:';
            if (isHttps) {
                console.log("Running on HTTPS");
                Cookies.set('task_id', data.task_id, {sameSite: 'None', secure: true, path: '/'});
                Cookies.set('request_params', requestParams, {sameSite: 'None', secure: true, path: '/'});
            } else {
                console.log("Running on HTTP");
                Cookies.set('task_id', data.task_id, {sameSite: 'Lax', secure: false, path: '/'});
                Cookies.set('request_params', requestParams, {sameSite: 'Lax', secure: false, path: '/'});
            }

            console.log('Task ID set in cookies:', Cookies.get('task_id'));
        } else {
            console.error('No task_id found in response data.');
        }
    };

    const formatDateRange = (range) => {
        const {departureDate, returnDate} = range;
        const formatDate = (date) => {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        };

        if (departureDate && returnDate) {
            return `${formatDate(departureDate)} - ${formatDate(returnDate)}`;
        } else {
            return calendarPlaceholder;
        }
    };

    const formattedDateRange = formatDateRange(dateRange);

    // Define necessary handler functions
    const handleChange = (name) => (newValue) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: newValue
        }));
    };

    const handleInputChange = (name) => (e) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: e.target.value
        }));
    };

    const handleRangeSelect = (range) => {
        setDateRange(range);
    };

    const handleSelectedTags = (tags) => {
        setSelectedTags(tags);
    };

    const handleSelectedTagsStep3 = (tags) => {
        setSelectedTagsStep3(tags);
    };

    const toggleIdealDestination = () => {
        setIdealDestination(prev => !prev);
    };

    switch (step) {
        case 1:
            return (
                <InitialDataStep
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleChange={handleChange}
                    handleInputChange={handleInputChange}
                    values={formData}
                    toggleIdealDestination={toggleIdealDestination}
                    idealDestination={idealDestination}
                    onRangeSelect={handleRangeSelect}
                    formattedDateRange={formattedDateRange}
                    setRangeNotSelectedError={setRangeNotSelectedError}
                />
            );
        case 2:
            return (
                <TravelTypeStep
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleInputChange={handleInputChange}
                    values={formData}
                    setTravelType={(travelType) =>
                        handleInputChange('travel_type')({target: {value: travelType}})
                    }
                />
            );
        case 3:
            return (
                <PreferredLocationsStep
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleSelectedTags={handleSelectedTagsStep3}
                    values={formData}
                    setPreferredLocation={(geo) =>
                        handleInputChange('geo')({target: {value: geo}})
                    }
                />
            );
        case 4:
            return (
                <CitiesPickStep
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleSelectedTags={handleSelectedTags}
                    selectedTags={selectedTagsStep3}
                    values={formData}
                />
            );
        case 5:
            return (
                <BudgetSmartphoneStep
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleInputChange={handleInputChange}
                    selectedCities={selectedCities}
                    values={formData}
                />
            );
        case 6:
            return (
                <div>
                    {isLoading ? (
                        <LoadingSpinner/>
                    ) : fetchError ? (
                        <div>
                            <p>Error: {fetchError}</p>
                            <p>Please try again later.</p>
                        </div>
                    ) : (
                        <p>Offers fetched successfully. Redirecting...</p>
                    )}
                </div>
            );
        default:
            console.log('Form Submitted:', formData, dateRange);
            return null;
    }
};

export default SearchForm;

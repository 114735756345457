import React from "react";
import SelectActivityButton from "../buttons/selectTagButton";
import './styles.css';
import '../../../commonStyles.css';

const Tag = ({ id, icon, alt, onClick, isSelected, setSelectedTag }) => {
  const handleClick = () => {
    setSelectedTag(id);
    onClick(id);
  };

  return (
    <div className={`tag bottom-shadow ${isSelected ? 'selected' : ''}`} onClick={handleClick}>
      <img src={icon} alt={alt} className={`tag-icon ${isSelected ? 'highlight' : ''}`} />
      <SelectActivityButton onClick={handleClick} isSelected={isSelected} />
    </div>
  );
};

export default Tag;
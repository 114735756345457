import React from 'react';
import '../styles.css';
import {ReactComponent as TaxiImage} from "../../../../../images/icons/car-taxi-front.svg";

const TransferOfferDetails = ({details}) => {
    // const pickupTime = new Date(details.start.dateTime).toLocaleTimeString([], {
    //     hour: '2-digit',
    //     minute: '2-digit',
    // });

    if (!details) {
        return <div className="error-message">No details available</div>;
    }

    return (
        <div className="offer-details">
            <h2 className="page-title">Transfer</h2>
            <div className="transfer-wrapper">
                <div className="transfer-wrapper-inner">
                    <div className="transfer-info-inner">
                        <div className="transfer-monitor highlight">
                            {details.segments.map((detail, index) => (
                                <React.Fragment key={index}>
                                    <div className="transfer-details-container">
                                        <div className="transfer-title">
                                            <TaxiImage/>
                                            {detail.vehicle.code || 'N/A'}
                                        </div>
                                        <div className="transfer-details">
                                            <div className="duration-details">
                                                <div className="duration-title">
                                                    DURATION
                                                </div>
                                                <div className="duration-content">
                                                    {detail.vehicle.duration || 'N/A'}
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransferOfferDetails;

export const SET_TRANSFER_FETCHED = 'SET_TRANSFER_FETCHED';
export const RESET_TRANSFER_FETCHED = 'RESET_TRANSFER_FETCHED';
export const setTransferFetched = (fetched) => ({
    type: SET_TRANSFER_FETCHED,
    payload: fetched
});

export const resetTransferFetched = () => ({
    type: RESET_TRANSFER_FETCHED
});

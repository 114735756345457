import {useDispatch, useSelector} from 'react-redux';
import ExpandableItem from './expandableItem/expandableItem';
import LoadingSpinner from '../../utils/loadingSpinner/loadingSpinner';

import './styles.css';
import '../../../commonStyles.css';
import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import GetRequest from "../../api/getRequest";
import {calculateVacationDays, extractNumberFromString} from "../../utils/calculationFunctions";
import {formatToTwoDecimals} from "../../utils/calculationFunctions";
import {extractCurrency} from "../../utils/calculationFunctions";

import {
    getNumberOfValidOffers,
    getRequestParamsFromCookies, processFlightToDestination,
    processHotelInfo, processFlightFromDestination, countFirstLevelChildren
} from "../../utils/dataProcessingFunctions";

import {setTransferFetched} from "../../../store/actions/transferActions";
import RenderDestinationButtons from "./carouselFunctions";
import SearchAgainButton from "../../tripOrganisation/buttons/searchAgainButton";

const NUMBER_OF_TRANSFER_POLL_ATTEMPTS = 5;
const TRANSFER_POLL_ATTEMPTS_DELAY = 3000;

function OffersCarousel({offers = [], isLoading}) {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Use navigate outside the useEffect

    const selectedOffers = useSelector((state) => state.offer.selectedOffers);
    const currentOfferId = useSelector((state) => state.offer.currentOffer);


    const {transferFetched} = useSelector(state => state.transfer);
    const currentRealOfferDetails = offers[currentOfferId] || null;


    useEffect(() => {
        if (offers === [] || offers.length === 0) {
            // Redirect to homepage or any other page if data is missing
            navigate('/');
        }
    }, [offers, navigate]);


    useEffect(() => {
        const pollTransfers = async () => {
            if (!transferFetched) {  // Only fetch if not already fetched
                for (let i = 0; i < NUMBER_OF_TRANSFER_POLL_ATTEMPTS; i++) {
                    try {
                        const path = 'api/fetch_transfers/';
                        const transferData = await GetRequest(path)();
                        if (transferData && transferData.message && transferData.message.length > 0) {
                            dispatch(setTransferFetched(true));
                            console.log("Transfer data received:", transferData);
                            break; // Stop polling if data is received
                        }
                        await new Promise(resolve => setTimeout(resolve, TRANSFER_POLL_ATTEMPTS_DELAY));
                    } catch (error) {
                        console.error("Error fetching data:", error);
                    }
                }
            }
        };

        if (offers.length > 0) {
            pollTransfers();
        }
    }, [offers, transferFetched, dispatch]);

    // Step 2: Create Mocked Data Directly in the Component
    const mockedOffers = offers.map((offer, index) => ({
        destination: `Destination ${index + 1}`,
        offers: [
            {
                id: 1,
                info1: {
                    "selection-box": {
                        option1: {price: '', time: ''},
                        option2: {price: '', time: ''},
                        option3: {price: '', time: ''}
                    },
                    details: [
                        {segments: [], totalDuration: ''},
                        {segments: [], totalDuration: ''},
                        {segments: [], totalDuration: ''}
                    ]
                }
            },
            {
                id: 2,
                info1: {
                    "selection-box": {
                        option1: {price: '12 €', time: '12:45'},
                        option2: {price: '43 €', time: '05:34'},
                        option3: {price: '23 €', time: '11:30'}
                    },
                    details: [
                        {
                            segments: [{
                                start: {
                                    dateTime: "2019-11-10T16:33:00",
                                    locationCode: "CDG"
                                },
                                vehicle: {
                                    code: "STANDARD",
                                    category: "BU",
                                    duration: "11 min",
                                    seats: [
                                        {
                                            count: 3
                                        }
                                    ],
                                    baggages: [
                                        {
                                            count: 3,
                                            size: "M"
                                        }
                                    ],
                                    imageURL: "https://provider.com/images/BU_VAN.png"
                                }
                            }]
                        },
                        {
                            segments: [{
                                start: {
                                    dateTime: "2019-11-10T10:30:00",
                                    locationCode: "ZAG"
                                },
                                vehicle: {
                                    code: "VAN",
                                    category: "BU",
                                    duration: "22 min",
                                    seats: [
                                        {
                                            count: 4
                                        }
                                    ],
                                    baggages: [
                                        {
                                            count: 3,
                                            size: "M"
                                        }
                                    ],
                                    imageURL: "https://provider.com/images/BU_VAN.png"
                                }
                            }]
                        },
                        {
                            segments: [{
                                start: {
                                    dateTime: "2019-11-10T13:30:00",
                                    locationCode: "BEG"
                                },
                                vehicle: {
                                    code: "STANDARD",
                                    category: "BU",
                                    duration: "51 min",
                                    seats: [
                                        {
                                            count: 3
                                        }
                                    ],
                                    baggages: [
                                        {
                                            count: 3,
                                            size: "M"
                                        }
                                    ],
                                    imageURL: "https://provider.com/images/BU_VAN.png"
                                }
                            }]
                        }
                    ]
                }
            },
            {
                id: 3,
                info1: {
                    "selection-box": {
                        option1: {price: '', name: ''},
                        option2: {price: '', name: ''},
                        option3: {price: '', name: ''}
                    },
                    details: [
                        {
                            segments: [{name: '', checkInTime: '', checkOutTime: '', roomInfo: ''}],
                            stars: '',
                            amenity_groups: '',
                            images: ''
                        },
                        {
                            segments: [{name: '', checkInTime: '', checkOutTime: '', roomInfo: ''}],
                            stars: '',
                            amenity_groups: '',
                            images: ''
                        },
                        {
                            segments: [{name: '', checkInTime: '', checkOutTime: '', roomInfo: ''}],
                            stars: '',
                            amenity_groups: '',
                            images: ''
                        },
                    ]
                }
            },
            {
                id: 4,
                info1: {
                    "selection-box": {
                        option1: {price: '21 €', time: '7:45'},
                        option2: {price: '10 €', time: '15:34'},
                        option3: {price: '8.55 €', time: '13:30'}
                    },
                    details: [
                        {
                            segments: [{
                                start: {
                                    dateTime: "2019-11-10T16:33:00",
                                    locationCode: "CDG"
                                },
                                vehicle: {
                                    code: "STANDARD",
                                    category: "BU",
                                    duration: "11 min",
                                    seats: [
                                        {
                                            count: 3
                                        }
                                    ],
                                    baggages: [
                                        {
                                            count: 3,
                                            size: "M"
                                        }
                                    ],
                                    imageURL: "https://provider.com/images/BU_VAN.png"
                                }
                            }]
                        },
                        {
                            segments: [{
                                start: {
                                    dateTime: "2019-11-10T10:30:00",
                                    locationCode: "ZAG"
                                },
                                vehicle: {
                                    code: "VAN",
                                    category: "BU",
                                    duration: "22 min",
                                    seats: [
                                        {
                                            count: 4
                                        }
                                    ],
                                    baggages: [
                                        {
                                            count: 3,
                                            size: "M"
                                        }
                                    ],
                                    imageURL: "https://provider.com/images/BU_VAN.png"
                                }
                            }]
                        },
                        {
                            segments: [{
                                start: {
                                    dateTime: "2019-11-10T13:30:00",
                                    locationCode: "BEG"
                                },
                                vehicle: {
                                    code: "STANDARD",
                                    category: "BU",
                                    duration: "51 min",
                                    seats: [
                                        {
                                            count: 3
                                        }
                                    ],
                                    baggages: [
                                        {
                                            count: 3,
                                            size: "M"
                                        }
                                    ],
                                    imageURL: "https://provider.com/images/BU_VAN.png"
                                }
                            }]
                        }
                    ]
                }
            },
            {
                id: 5,
                info1: {
                    "selection-box": {
                        option1: {price: '', time: ''},
                        option2: {price: '', time: ''},
                        option3: {price: '', time: ''}
                    },
                    details: [
                        {segments: [], totalDuration: ''},
                        {segments: [], totalDuration: ''},
                        {segments: [], totalDuration: ''}
                    ]
                }
            },
        ]
    }));


    const isOfferValid = (currentRealOfferDetails) => {
        if (currentRealOfferDetails) {
            return currentRealOfferDetails.offers.data !== "no hotels found on the location";
        } else {
            console.log("offer is null")
            return false;
        }
    }

    console.log("isoffer valid", isOfferValid(currentRealOfferDetails));

    /**
     * Function to safely extract offer details from the mockedOffers object.
     * @param {Object} offers - The mockedOffers object containing the offers data.
     * @param {string} offerId - The current offer identifier.
     * @param {number} index - The index of the specific offer to extract.
     * @returns {Object|null} - The extracted offer details or null if not found.
     */
    const getOfferDetails = (offers, offerId, index) => {
        try {
            const offerDetails = offers[offerId]?.offers?.[index];
            if (!offerDetails) {
                throw new Error("No details");
            }
            return offerDetails;
        } catch (error) {
            console.error(`No details for offerId: ${offerId} at index: ${index}`);
            return null;
        }
    };
    let currentOfferDetailsFlightTo = getOfferDetails(mockedOffers, currentOfferId, 0)
    let currentOfferDetailsHotel = getOfferDetails(mockedOffers, currentOfferId, 2)
    let currentOfferDetailsFlightBack = getOfferDetails(mockedOffers, currentOfferId, 4)

    if (currentRealOfferDetails == null) {
        console.log("no data returned")
        navigate('/')
        return null; // Ensure nothing renders if data is not loaded
    }

    let validOffer = isOfferValid(currentRealOfferDetails);
    // Process each hotel info
    if (currentRealOfferDetails) {
        if (validOffer) {
            // parsing hotels
            if (currentRealOfferDetails?.offers?.data?.[currentOfferId]?.[1] && Array.isArray(currentRealOfferDetails.offers.data[currentOfferId][1])) {
                currentRealOfferDetails.offers.data[currentOfferId][1].forEach((hotelInfo, index) => {

                    if (hotelInfo) {
                        processHotelInfo(hotelInfo, index + 1, currentOfferDetailsHotel);
                    } else {
                        console.log(`No hotel info for offer ${index}`);
                    }
                });
            } else {
                console.log("No valid hotel info available");
                console.log(currentRealOfferDetails?.offers?.data?.[currentOfferId])
            }

            // Using the function
            if (currentRealOfferDetails && currentRealOfferDetails.offers.data) {
                processFlightToDestination(currentRealOfferDetails.offers.data, currentOfferDetailsFlightTo);
            } else {
                console.log("No real offer details available for departure");
            }

            // Parsing flight from destination data
            if (currentRealOfferDetails && currentRealOfferDetails.offers.data) {
                processFlightFromDestination(currentRealOfferDetails.offers.data, currentOfferDetailsFlightBack);
            } else {
                console.log("currentRealOfferDetails is null or no data when processing flights");
            }

        } else {
            console.log("no hotel data for current offer");
        }
    } else {
        navigate('/')
        return null; // Ensure nothing renders if data is not loaded
    }

    if (currentRealOfferDetails) {
        mockedOffers[currentOfferId].offers[0] = currentOfferDetailsFlightTo;
        mockedOffers[currentOfferId].offers[2] = currentOfferDetailsHotel;
        mockedOffers[currentOfferId].offers[4] = currentOfferDetailsFlightBack;
        mockedOffers[currentOfferId].destination = currentRealOfferDetails.offers.city;
        console.log("mockedOffers finale:", mockedOffers);
    } else {
        console.log("no data");
        navigate('/')
        return null; // Ensure nothing renders if data is not loaded
    }

    // Calculating Grand total
    let travelOfferTotal = 0;
    let currency;
    for (let itemId = 1; itemId <= 5; itemId++) {
        let selectedOptionForItem = selectedOffers[currentOfferId]?.[itemId];

        if (selectedOptionForItem === undefined) {
            selectedOptionForItem = 0;
        }
        let option = [`option${selectedOptionForItem + 1}`]
        let itemPrice = mockedOffers[currentOfferId].offers[itemId - 1].info1["selection-box"][option].price

        let ItemPriceNumber = extractNumberFromString(itemPrice);
        travelOfferTotal = travelOfferTotal + ItemPriceNumber

        currency = extractCurrency(itemPrice)
    }
    travelOfferTotal = formatToTwoDecimals(travelOfferTotal);
    travelOfferTotal = travelOfferTotal + currency;

    // Getting data from cookies inserted by user in step 1
    console.log("get request params and log");
    let cookieData = getRequestParamsFromCookies();
    console.log(cookieData);

    let totalPeople;
    if (cookieData) {
        const numberOfAdults = cookieData.adults;
        const numberOfChildren = cookieData.children;
        totalPeople = numberOfChildren + numberOfAdults;
    }
    else {
        totalPeople = '';
    }

    const totalNumberOfOffers = countFirstLevelChildren(offers);

    let numberOfValidOffers = getNumberOfValidOffers(offers);
return (
    <div className="offers-container">
        {isLoading ? (
            <LoadingSpinner />
        ) : (
            mockedOffers.length > 0 && currentOfferDetailsFlightTo ? (
                <>
                    <div className="offer-item">
                        <div className="offer-header">
                            <div className="offers-title-container">
                                <div className="offers-title-container-inner">
                                    <div className="offers-monitor-outer">
                                        <div className="offers-monitor highlight deep-effect">
                                            {/* Check if departureDate and returnDate are valid */}
                                            {cookieData?.departureDate && cookieData?.returnDate ? (
                                                <p className="vacation-range">
                                                    {calculateVacationDays(cookieData.departureDate, cookieData.returnDate)} days
                                                </p>
                                            ) : (
                                                <p className="vacation-range">Offer expired</p>
                                            )}

                                            <div className="grand-total-wrapper">
                                                <div className="grand-total-icons">
                                                    For {totalPeople} Travelers
                                                </div>
                                                <p className="grand-total">Total: {travelOfferTotal}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {RenderDestinationButtons(offers, totalNumberOfOffers)}
                        </div>
                        <div className="offers-list-wrapper">
                            {validOffer ? (
                                <ExpandableItem
                                    myListData={mockedOffers[currentOfferId].offers}
                                    onOfferSelect={(offer) => console.log('Offer selected:', offer)}
                                />
                            ) : (
                                <>
                                <p className="no-hotels-message">
                                    There are no hotels found for this destination. Please check other offer(s) or search with different criteria.
                                </p>
                                <SearchAgainButton />
                                </>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <p>No offers available</p>
            )
        )}
    </div>
);

}

export default OffersCarousel;

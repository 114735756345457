import React, {useEffect, useState} from 'react';
import '../styles.css';
import '../../../../../commonStyles.css';

import FullScreenImageViewer from './FullScreenImageViewer';
import ImagesNavigation from "../../../../footer/imagesNavigation"; // Import the full-screen viewer

const ImagesPage = ({details}) => {

    const Images = details.images.map(url => url.replace('{size}', '1024x768'))

    const [currentIndex, setCurrentIndex] = useState(0);
    const [preloadedImages, setPreloadedImages] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const totalImages = Images.length;

    useEffect(() => {
        const loadImage = src => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = src;
                img.onload = () => resolve(src);
                img.onerror = reject;
            });
        };

        const preloadImages = async () => {
            try {
                const images = await Promise.all(Images.map(loadImage));
                setPreloadedImages(images);
            } catch (error) {
                console.error('Error preloading images:', error);
            }
        };

        preloadImages();
    }, [Images]);

    if (!details) {
        return <div className="error-message">No details available</div>;
    }

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + totalImages) % totalImages);
    };

    const handleImageClick = (index) => {
        setCurrentIndex(index);
        setIsFullScreen(true);
    };

    const handleCloseFullScreen = () => {
        setIsFullScreen(false);
    };

    return (
        <>
            <div className="images-wrapper-outer bottom-shadow">
                <div className="images-wrapper">
                    <div className="images-container-outer">
                        <div className="images-container">
                            <div className="images-container-inner">
                                <img
                                    src={Images[currentIndex]}
                                    alt={`Hotel Pictures `}
                                    className="image"
                                    onClick={() => handleImageClick(currentIndex)}
                                />
                            </div>
                            <div className="image-navigation">
                                <span>{currentIndex + 1}/{totalImages}</span>
                            </div>
                        </div>
                        <div className="images-buttons-wrapper">
                            <ImagesNavigation
                                onNextClick={handleNext}
                                onPreviousClick={handlePrev}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {isFullScreen && (
                <FullScreenImageViewer
                    images={preloadedImages}
                    currentIndex={currentIndex}
                    onClose={handleCloseFullScreen}
                    onNext={handleNext}
                    onPrev={handlePrev}
                />
            )}
        </>
    );
};

export default ImagesPage;

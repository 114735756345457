import React, { useState, useRef, useEffect } from "react";
import Counter from "../../../counter/Counter";
import CounterChildren from "../../../counter/CounterChildren";
import Autocomplete from "../../autocomplete/autocomplete";
import "./styles.css";
import "../styles.css";
import "../../../../commonStyles.css";
import CalendarButton from "../../buttons/calendarButton";
import CustomCalendar from "../../../datepicker/datepicker";
import NavigationInitial from "../../../footer/initialNavigation";

function InitialDataStep({
    nextStep,
    handleInputChange,
    handleChange,
    onRangeSelect,
    values,
    formattedDateRange,
    setRangeNotSelectedError,
}) {
    const [isValidSelection, setIsValidSelection] = useState(false);
    const [adultsError, setAdultsError] = useState("");
    const [departureLocationError, setDepartureLocationError] = useState("");
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [rangeNotSelectedErrorLocal, setRangeNotSelectedErrorLocal] = useState(false);

    const calendarRef = useRef(null);
    const calendarPlaceholder = 'select dates';

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setCalendarOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleCalendarButtonClick = () => {
        setCalendarOpen((prev) => !prev);
    };

    const handleCloseCalendar = () => {
        setCalendarOpen(false);
    };

    const proceedToNextStep = () => {
        if (values.adults <= 0) {
            setAdultsError("Please add at least one adult.");
            return;
        } else {
            setAdultsError("");
        }

        if (!isValidSelection) {
            setDepartureLocationError("Please select a valid departure location from the list.");
            return;
        } else {
            setDepartureLocationError("");
        }

        if (!formattedDateRange || formattedDateRange === calendarPlaceholder) {
            setRangeNotSelectedErrorLocal(true);
            setRangeNotSelectedError(true);
            return;
        } else {
            setRangeNotSelectedErrorLocal(false);
            setRangeNotSelectedError(false);
        }

        nextStep();
    };

    return (
        <div className="form-inline-wrapper">
            {departureLocationError && (
                <div className="error-detail">{departureLocationError}</div>
            )}
            <div className="autocomplete-wrapper">
                <p className="initial-data-label">STARTING AIRPORT</p>
                <Autocomplete
                    handleInputChange={handleInputChange}
                    values={values}
                    isValidSelection={isValidSelection}
                    setIsValidSelection={setIsValidSelection}
                    setDepartureLocationError={setDepartureLocationError}
                />
            </div>

            <div className="time-range-container">
                {rangeNotSelectedErrorLocal && (
                    <div className="error-detail">
                        Please select both start and end dates.
                    </div>
                )}
                <p className="initial-data-label">FROM - TO</p>
                <div className="time-range-box">
                    <div className="calendar-button-wrapper">
                        <CalendarButton
                            isActive={calendarOpen}
                            onClick={handleCalendarButtonClick}
                        />
                    </div>
                    <div onClick={handleCalendarButtonClick} className="date-range-info rounded-right-button">
                        <div className="date-range-info-inner rounded-right-button">
                            <div
                                className={`range-display ${formattedDateRange !== calendarPlaceholder ? 'highlight' : ''}`}>
                                {formattedDateRange}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="calendar-wrapper">
                {calendarOpen && (
                    <div ref={calendarRef}>
                        <CustomCalendar
                            isOpen={calendarOpen}
                            onClose={handleCloseCalendar}
                            onRangeSelect={onRangeSelect}
                        />
                    </div>
                )}
            </div>
            <div className="counter-wrapper">
                {adultsError && (
                    <div className="error-detail">{adultsError}</div>
                )}
                <p className="initial-data-label">ADULTS</p>
                <Counter
                    value={values.adults}
                    onChange={handleChange("adults")}
                />
            </div>
            <div className="counter-wrapper">
                <p className="initial-data-label">CHILDREN</p>
                <CounterChildren
                    value={values.children}
                    onChange={handleChange("children")}
                />
            </div>
            <NavigationInitial
                onNextClick={proceedToNextStep}
                nextDisabled={!isValidSelection || values.adults <= 0 || rangeNotSelectedErrorLocal}
            />
        </div>
    );
}

export default InitialDataStep;

// reducers/offerReducer.js
import { SELECT_CURRENT_OFFER, UPDATE_OFFER_SELECTION, RESET_OFFERS } from "../actions/offerActions";

const initialState = {
  selectedOffers: {},
  currentOffer: 1,  // Assuming the first offer is selected by default
};

const offerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_CURRENT_OFFER:
      console.log('SELECT_CURRENT_OFFER action dispatched:', action.payload);
      return {
        ...state,
        currentOffer: action.payload,
      };

    case UPDATE_OFFER_SELECTION:
      const { offerId, itemId, optionId } = action.payload;
      const updatedSelectedOffers = {
        ...state.selectedOffers,
        [offerId]: {
          ...state.selectedOffers[offerId],
          [itemId]: optionId,
        },
      };

      return {
        ...state,
        selectedOffers: updatedSelectedOffers,
      };

    case RESET_OFFERS:
      console.log('RESET_OFFERS action dispatched');
      return {
        ...state,
        selectedOffers: {},
        currentOffer: 0,
      };

    default:
      return state;
  }
};

export default offerReducer;

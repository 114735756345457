import React, {useState} from "react";
import {useNavigate} from "react-router-dom"; // Import useNavigate
import "./styles.css";

const SearchAgainButton = (reload = false) => {
    const [isPressed, setIsPressed] = useState(false);
    const navigate = useNavigate(); // Initialize the navigate function

    const handleClick = () => {
        setIsPressed(true);
        setTimeout(() => {
            setIsPressed(false);
        }, 300);

        if (reload) {
            window.location.reload()
        } else {
            navigate('/'); // Navigate to the root URL
        }

    };

    return (
        <div
            className={`button-search-outer ${isPressed ? "pressed" : ""}`}
            onClick={handleClick}
        >
            <div className={`button-search-inner ${isPressed ? "translate" : ""}`}>
                Search Again
            </div>
        </div>
    );
};

export default SearchAgainButton;

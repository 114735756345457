import React, {useEffect, useState} from "react";

import GetRequest from "../../../api/getRequest";
import LoadingSpinner from "../../../utils/loadingSpinner/loadingSpinner";
import '../../../../commonStyles.css';
import './styles.css';
import '../styles.css';
import CountryButton from "../../buttons/countryButton";
import Navigation from "../../../footer/navigation";
import SearchAgainButton from "../../buttons/searchAgainButton";
import Cookies from "js-cookie";

function PreferredLocationsStep({nextStep, prevStep, handleSelectedTags}) {
    const [countries, setCountries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTags, setSelectedTags] = useState([]);
    const [noResponse, setNoResponse] = useState(false); // State to track no response
    const MAX_ATTEMPTS = 10;
    const RETRY_DELAY = 2000; // in milliseconds

    useEffect(() => {
        let isMounted = true;

        const fetchCountriesWithRetry = async (attempt = 0) => {
            if (attempt >= MAX_ATTEMPTS) {
                if (isMounted) {
                    setIsLoading(false);
                    setNoResponse(true); // Set noResponse to true when max attempts are reached
                }
                return;
            }

            try {
                const path = 'api/get_countries/';
                const requestData = await GetRequest(path);
                const data = await requestData();

                if (isMounted && data && data.status === 'Success' && data.countries && data.countries.length > 0) {
                    setCountries(data.countries);
                    setIsLoading(false);
                    setNoResponse(false); // Reset noResponse on successful fetch
                } else {
                    throw new Error("Invalid data structure or empty countries list");
                }
            } catch (error) {
                console.error("Fetching countries failed: ", error);
                setTimeout(() => fetchCountriesWithRetry(attempt + 1), RETRY_DELAY);
            }
        };

        fetchCountriesWithRetry();

        return () => {
            isMounted = false;
        };
    }, []); // Only run once on component mount

    const handleTagClick = (country) => {
        const updatedTags = [...selectedTags];
        const tagIndex = updatedTags.indexOf(country);
        if (tagIndex !== -1) {
            updatedTags.splice(tagIndex, 1); // Remove the country if already selected
        } else {
            updatedTags.push(country); // Add the country if not selected
        }
        setSelectedTags(updatedTags);
        handleSelectedTags(updatedTags);
    };

    const handleNextStep = () => {

        const isHttps = window.location.protocol === 'https:';
            if (isHttps) {
                console.log("Running on HTTPS");
                Cookies.set('selected_countries', JSON.stringify(selectedTags), {sameSite: 'None', secure: true, path: '/'});
            } else {
                console.log("Running on HTTP");
                Cookies.set('selected_countries', JSON.stringify(selectedTags), {sameSite: 'Lax', secure: false, path: '/'});
            }

        nextStep(selectedTags);
    };

    return (
        <div className="form-inline-wrapper">
            <h2 className="page-title">Choose one or more countries</h2>
            <p className="page-subtitle">to help us provide more relevant offers</p>
            {isLoading ? (
                <div className="loader-container">
                    <LoadingSpinner/>
                </div>
            ) : noResponse ? (
                <>
                    <div className="no-response">
                        <p>Server is currently busy. Please try again a bit later.</p>
                    </div>
                    <SearchAgainButton reload={true}/> {/* Updated to use handleSearchAgain */}
                </>
            ) : (
                <div>
                    <div className="countries-container">
                        {countries.map((country, index) => (
                            <div className="button-country-container bottom-shadow" key={index}>
                                <CountryButton
                                    onClick={() => handleTagClick(country)}
                                    country={country}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <div>
                <Navigation
                    onPreviousClick={prevStep}
                    onNextClick={handleNextStep}
                    nextDisabled={false}
                />
            </div>
        </div>
    );
}

export default PreferredLocationsStep;

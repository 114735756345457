import React, { useEffect, useState } from "react";
import PostRequest from "../../../api/postRequest";
import LoadingSpinner from "../../../utils/loadingSpinner/loadingSpinner";
import '../../../../commonStyles.css';
import Navigation from "../../../footer/navigation";
import CountryInfoButton from "../../buttons/countryInfoButton";
import CityButton from "../../buttons/cityButton";
import Cookies from "js-cookie";

const MAX_CITIES_DISPLAYED = 50;
const MAX_CITIES_TO_SELECT = 3;
const MAX_ATTEMPTS_TO_FETCH = 3;
const RETRY_DELAY = 800;
const ERROR_DISPLAY_TIME = 5000;

function CitiesPickStep({ nextStep, prevStep, selectedTags, handleSelectedTags }) {
    const [cities, setCities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCities, setSelectedCities] = useState([]);
    const [showNoCitiesError, setShowNoCitiesError] = useState(false);
    const [showMaxCitiesWarning, setShowMaxCitiesWarning] = useState(false); // State for max cities warning

    const [retryCount, setRetryCount] = useState(0);

    useEffect(() => {

        const storedCountries = JSON.parse(Cookies.get('selected_countries'));
        const fetchCitiesWithRetry = async () => {

            let requestData = []
                    if (selectedTags) {
                        console.log("tags case")
                        requestData = selectedTags;
                    }else if (storedCountries){
                        console.log("cookie case")
                        requestData = storedCountries;
                    }
            let attempt = 0;
            let requestPath = 'api/get_cities/';
            while (attempt < MAX_ATTEMPTS_TO_FETCH) {
                const data = await PostRequest({ requestData }, requestPath, 'json');
                if (data.cities && data.cities.length > 0) {
                    setCities(data.cities.slice(0, MAX_CITIES_DISPLAYED));
                    setIsLoading(false);
                    return;
                }
                await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
                attempt++;
            }
            console.error("Failed to fetch cities after multiple attempts");
        };

        if (retryCount < MAX_ATTEMPTS_TO_FETCH) {
            fetchCitiesWithRetry();
            setRetryCount(retryCount + 1);
        }
    }, []);

    const handleTagClick = (city) => {
        const updatedCities = [...selectedCities];
        const cityIndex = updatedCities.indexOf(city);
        if (cityIndex !== -1) {
            updatedCities.splice(cityIndex, 1);
        } else if (selectedCities.length < MAX_CITIES_TO_SELECT) {
            updatedCities.push(city);
        } else {
            setShowMaxCitiesWarning(true); // Show warning if maximum cities already selected
            setTimeout(() => setShowMaxCitiesWarning(false), ERROR_DISPLAY_TIME); // Hide warning after 5 seconds
            return; // Prevent adding more cities
        }
        setSelectedCities(updatedCities);
        handleSelectedTags(updatedCities);
    };

    const handleNextStep = async () => {
        if (selectedCities.length === 0) {
            setShowNoCitiesError(true);
            setTimeout(() => setShowNoCitiesError(false), ERROR_DISPLAY_TIME);
            return;
        }

        try {
            await PostRequest({ selectedCities }, 'api/set_cities/', 'json');
            console.log('Cities set successfully');
            console.log(selectedCities);
            nextStep(selectedCities);
        } catch (error) {
            console.error("Error setting cities: ", error);
        }
    };

    return (
        <div className="form-inline-wrapper">

            {isLoading ? (
                <LoadingSpinner/>
            ) : (

                <>
                    <h2 className='page-title'>Choose up to three cities</h2>
                    <p>to help us provide more relevant results</p>
                    {showNoCitiesError && (
                        <p className="error-detail">You need to select at least one city.</p>
                    )}

                    <div className="cities-container">
                        {cities.map((city, index) => (
                            <div className="button-city-container" key={index}>
                                <CityButton
                                    onClick={() => handleTagClick(city)}
                                    city={city}
                                    selected={selectedCities.includes(city)}
                                    disabled={selectedCities.length >= MAX_CITIES_TO_SELECT && !selectedCities.includes(city)}
                                    setShowMaxCitiesWarning={setShowMaxCitiesWarning} // Pass setter function
                                />
                                <CountryInfoButton onClick={() => {
                                }}/>
                            </div>
                        ))}
                    </div>

                    {showMaxCitiesWarning && (
                        <p className="error-detail">Maximum {MAX_CITIES_TO_SELECT} cities allowed to select</p>
                    )}

                    <Navigation
                        onPreviousClick={prevStep}
                        onNextClick={handleNextStep}
                        nextDisabled={false}
                    />
                </>
            )
            }
        </div>
    )
        ;
}

export default CitiesPickStep;

import React from 'react';
import ImagesPage from './ImagesPage';
import './styles.css';

const HotelAmenitiesPage = ({ details }) => {
    // Debugging logs to ensure we have the expected data
    console.log('Received details:', details);

    // Extract amenity_groups from the first segment
    const amenityGroups = details.segments[0]?.amenity_groups || "amenity groups not available";

    // Debugging logs to check extracted amenity_groups
    console.log('Extracted amenityGroups:', amenityGroups);

    return (
        <>
            <div className="amenities-wrapper">
                {/* Render ImagesPage */}
                {details.images !== "" && <ImagesPage details={details} />}


                {/* Render amenities if available */}
                {amenityGroups !== "amenity groups not available" && (
                    <div className="amenities-content">
                        {amenityGroups.map((group, index) => (
                            <div key={index} className="amenities-group">
                                <h2 className="amenities-title">{group.group_name}</h2>
                                <ul className="amenities-list">
                                    {group.amenities.map((amenity, idx) => (
                                        <li key={idx} className="amenity-item">{amenity}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default HotelAmenitiesPage;

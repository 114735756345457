import React, { useState } from "react";
import SkiingIcon from '../../../../images/tags/travel_type/retro/v2/skiing_tag.png';
import SummerVacationIcon from '../../../../images/tags/travel_type/retro/v2/SUMMER_VACATION_PIXELIZE.png';
import HikingIcon from '../../../../images/tags/travel_type/retro/hiking.png';
import UrbanExploration from '../../../../images/tags/travel_type/retro/v2/urban_exploration-bold-2.png';
import ShoppingIcon from '../../../../images/tags/travel_type/retro/shopping.png';
import YachtingIcon from '../../../../images/tags/travel_type/retro/yachting.png';
import PostRequest from "../../../api/postRequest";
import Navigation from "../../../footer/navigation";
import Tag from "../../tag/tag";

function TravelTypeStep({ nextStep, prevStep }) {
  const [selectedTag, setSelectedTag] = useState('');
  const [error, setError] = useState(false);  // New state to track error

  const handleTagClick = (tagId) => {
    setSelectedTag(tagId);
    setError(false);  // Reset error when a tag is selected
  };

  const handleNextStep = () => {
    if (selectedTag) {
      sendActivityMessage();
      nextStep();
    } else {
      setError(true);  // Show error if no tag is selected
    }
  };

  const sendActivityMessage = async () => {
    let path = 'api/set_activity/';

    try {
      const requestData = { "activity_tag": selectedTag };
      const response = await PostRequest(requestData, path, 'json');
      console.log("Activity setting");
      console.log(response); // Print the entire response object
      console.log("Message:", response.message);
    } catch (error) {
      console.error("Error setting activity: ", error);
    }
  };

  const tags = [
    { id: 'skiing', icon: SkiingIcon, alt: 'Skiing' },
    { id: 'summer_vacation', icon: SummerVacationIcon, alt: 'Summer Vacation' },
    { id: 'hiking', icon: HikingIcon, alt: 'Hiking' },
    { id: 'urban_exploration', icon: UrbanExploration, alt: 'Urban Exploration' },
    { id: 'shopping', icon: ShoppingIcon, alt: 'Shopping' },
    { id: 'yachting', icon: YachtingIcon, alt: 'Yachting' },
  ];

  return (
    <div className="form-inline-wrapper">
      <h2 className="page-title">Do you have a preferred type of travel?</h2>
      <div className="tags-container">
        {tags.map(tag => (
          <Tag
            key={tag.id}
            id={tag.id}
            icon={tag.icon}
            alt={tag.alt}
            onClick={handleTagClick}
            isSelected={selectedTag === tag.id}
            setSelectedTag={setSelectedTag}
          />
        ))}
      </div>

      {/* Conditionally render error message if no tag is selected */}
      {error && (
        <div className="error-detail">
          Please select a tag to continue
        </div>
      )}

      <Navigation
        onPreviousClick={prevStep}
        onNextClick={handleNextStep}
        isNextDisabled={false}  // Allow click even if no tag, we'll handle it in handleNextStep
      />
    </div>
  );
}

export default TravelTypeStep;

import React from "react";
import "../styles.css";

const RightSelectionButton = ({isSelected, onClick, price}) => {

    return (
        <div
            className={`selection-option right ${isSelected ? "selected" : ""}`}
            onClick={onClick}
        >
            <div className={`selection-option-inner right ${isSelected ? "translate" : ""}`}>
                {price}
            </div>
        </div>
    );
};

export default RightSelectionButton;

const PostRequest = async (requestData, path, responseType) => {
    try {
        let requestUrl;
        if (process.env.REACT_APP_WORKMODE === 'dev') {
          requestUrl = `http://${process.env.REACT_APP_URL}:${process.env.REACT_APP_DJANGO_PORT}/${path}`;
        }
        else {
          requestUrl = `${path}`;
        }
        const response = await fetch(
            requestUrl,
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData)
            }
        );
        if (response.ok) {
            if (responseType === 'json') {
                const data = await response.json();
                return data;
            }
            const data = await response.body;
            return data;


        } else {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
    } catch (error) {
        console.error("Fetch failed: ", error);
        throw error; // Rethrow the error to handle it in the component
    }
};

export default PostRequest;

// src/utils/calculationFunctions.js

/**
 * Extracts numbers from a string and converts it to a floating-point number.
 * @param {string} str - The string to process.
 * @return {number} - The extracted floating-point number.
 */
function extractNumberFromString(str) {
    const cleanedString = str.replace(/[^0-9.]/g, '');
    return parseFloat(cleanedString);
}

/**
 * Extracts currency symbols from a string.
 * @param {string} str - The string containing the currency.
 * @return {string} - The extracted currency symbols.
 */
function extractCurrency(str) {
    return str.replace(/[0-9.,\s]/g, '').trim();
}

/**
 * Formats a number to two decimal places.
 * @param {number} num - The number to format.
 * @return {number} - The number formatted to two decimal places.
 */
function formatToTwoDecimals(num) {
    return parseFloat(num.toFixed(2));
}

const formatCurrency = (currency) => {
    return currency === 'EUR' ? `€` : `${currency}`;
};

const calculateVacationDays = (departureDate, returnDate) => {
  // Convert the string dates to JavaScript Date objects
  const startDate = new Date(departureDate);
  const endDate = new Date(returnDate);

  // Calculate the difference in time
  const timeDifference = endDate - startDate;

  // Convert the time difference from milliseconds to days
  const numberOfDays = timeDifference / (1000 * 60 * 60 * 24);

  return numberOfDays;
};

const modifyDateTimeString = (dateTimeString) => {
            // Regular expression to match both date-time and date-only formats
            const dateTimeRegex = /^(\d{4})-(\d{2})-(\d{2})(?:T(\d{2}):(\d{2}):(\d{2}))?$/;

            // Use the regex to extract the components of the date-time string
            const match = dateTimeString.match(dateTimeRegex);
            if (!match) {
                // If the string does not match the expected format, return the original string or handle the error
                console.error('Invalid date-time format:', dateTimeString);
                return dateTimeString;
            }

            // Destructure the components from the match array
            const [, year, month, day, hour, minute] = match;

            // Check if the hour and minute are present
            if (hour && minute) {
                // Return the reformatted string for date-time
                // return `${year}/${month}/${day} at ${hour}:${minute}`;
                return `${hour}:${minute}`;
            }

            // Return the reformatted string for date-only
            return `${year}/${month}/${day}`;
        };

// Export all utility functions
export {extractNumberFromString, extractCurrency, formatToTwoDecimals, formatCurrency, calculateVacationDays, modifyDateTimeString};

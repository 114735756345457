import {formatCurrency} from "./calculationFunctions";
import Cookies from "js-cookie";


/**
 * Function to safely extract hotel details from the hotelInfo object.
 * @param {Object} hotelInfo - real data object containing hotels data.
 * @param {Object} currentOfferDetailsHotel - object where extracted data is stored
 * @param {number} optionIndex - The index of the specific hotel offer, can be 1,2 and 3
 *
 * @returns {Object|null} - The extracted hotel details or null if not found.
 */
function processHotelInfo(hotelInfo, optionIndex, currentOfferDetailsHotel) {
    try {
        let totalPrice = hotelInfo.dynamic_data.payment_options.payment_types[0].amount;
        let currency = hotelInfo.dynamic_data.payment_options.payment_types[0].currency_code;
        let hotelName = hotelInfo.static_data.name;
        let checkInTime = hotelInfo.static_data.check_in_time;
        let checkOutTime = hotelInfo.static_data.check_out_time;
        let starRating = hotelInfo.static_data.star_rating;
        let amenityGroups = hotelInfo.static_data.amenity_groups;
        let hotelImages = hotelInfo.static_data.images;
        let roomInfo = hotelInfo.dynamic_data.room_data_trans.main_name;

        currentOfferDetailsHotel.info1["selection-box"][`option${optionIndex}`].price = `${totalPrice}${formatCurrency(currency)}`;
        currentOfferDetailsHotel.info1["selection-box"][`option${optionIndex}`].name = hotelName;
        currentOfferDetailsHotel.info1.details[optionIndex - 1].segments[0].name = hotelName;
        currentOfferDetailsHotel.info1.details[optionIndex - 1].segments[0].checkInTime = checkInTime;
        currentOfferDetailsHotel.info1.details[optionIndex - 1].segments[0].checkOutTime = checkOutTime;
        currentOfferDetailsHotel.info1.details[optionIndex - 1].segments[0].stars = starRating;
        currentOfferDetailsHotel.info1.details[optionIndex - 1].segments[0].amenity_groups = amenityGroups;
        currentOfferDetailsHotel.info1.details[optionIndex - 1].images = hotelImages;
        currentOfferDetailsHotel.info1.details[optionIndex - 1].segments[0].roomInfo = roomInfo;

    } catch (error) {
        console.log(`No hotel ${optionIndex} info`);
        console.error(error);
    }
    return currentOfferDetailsHotel;
}

// Function to process flight to destination data
const processFlightToDestination = (offerData, flightDetails) => {
    if (!offerData || !Array.isArray(offerData)) {
        console.log("No real offer details available for departure");
        return;
    }

    offerData.forEach((offer, index) => {
        const realOfferFlightInfo = offer[0][0];
        const option = `option${index + 1}`;

        // Updating the flight details
        flightDetails.info1["selection-box"][option].price =
            `${realOfferFlightInfo["price"]["grandTotal"]}${formatCurrency(realOfferFlightInfo["price"]["currency"])}`;
        flightDetails.info1["selection-box"][option].time =
            realOfferFlightInfo.itineraries[0].segments[0].departure.at;
        flightDetails.info1.details[index].totalDuration = realOfferFlightInfo.itineraries[0].duration;
        flightDetails.info1.details[index].segments = realOfferFlightInfo.itineraries[0].segments;
    });

    console.log("Finished processing flights to destination");
};


// Function to process return flight details
const processFlightFromDestination = (realOfferData, flightDetails) => {
    if (!realOfferData || !Array.isArray(realOfferData)) {
        console.log("No real offer details available for return");
        return;
    }

    realOfferData.forEach((realOffer, index) => {
        const option = `option${index + 1}`;
        const detailIndex = index;

        if (realOffer && realOffer[0][0]["oneWay"]) {
            const oneWayOffer = realOffer[0][1];
            flightDetails.info1["selection-box"][option].price =
                `${oneWayOffer["price"]["grandTotal"]}${formatCurrency(oneWayOffer["price"]["currency"])}`;
            flightDetails.info1["selection-box"][option].time =
                oneWayOffer.itineraries[0].segments[0].departure.at;
            flightDetails.info1.details[detailIndex].totalDuration = oneWayOffer.itineraries[0].duration;
            flightDetails.info1.details[detailIndex].segments = oneWayOffer.itineraries[0].segments;
        } else if (realOffer) {
            const roundTripOffer = realOffer[0][0];
            flightDetails.info1["selection-box"][option].price =
                `${roundTripOffer["price"]["grandTotal"]}${formatCurrency(roundTripOffer["price"]["currency"])}`;
            flightDetails.info1["selection-box"][option].time =
                roundTripOffer.itineraries[1].segments[0].departure.at;
            flightDetails.info1.details[detailIndex].totalDuration = roundTripOffer.itineraries[1].duration;
            flightDetails.info1.details[detailIndex].segments = roundTripOffer.itineraries[1].segments;
        }
    });

    console.log("Finished processing return flights");
};


/**
 * Function to get cookies set after first step in order to use data on offers page
 * **/
const getRequestParamsFromCookies = () => {
    // Retrieve the cookie by its name, which stores JSON string
    const params = Cookies.get('request_params');
    // Check if the params exist and are not undefined
    if (params) {
        try {
            // Parse the JSON string back into an object
            const paramsObj = JSON.parse(params);
            // Return the departureDate or any other required value
            return paramsObj
        } catch (error) {
            console.error("Error parsing JSON from cookies", error);
            return null;  // Return null or default value in case of error
        }
    }
    return null; // Return null if the cookie doesn't exist
};


function countFirstLevelChildren(json) {
    if (typeof json === 'object' && json !== null) {
        return Object.keys(json).length; // Returns the number of direct children (first level)
    }
    return 0; // Return 0 if it's not a valid object
}

const getNumberOfValidOffers = (offers) => {
    let numberOfValidOffers = 0;

    // Loop through indexes 1, 2, and 3
    for (let index = 0; index <= 2; index++) {
        const details = offers[index] || null;
        // console.log("inside the function ", details.offers.data )
        if (!details || details.offers.data === "no hotels found on the location") {
            console.log(`Invalid offer for city at index ${index}`);
            console.log(details)
        } else {
            numberOfValidOffers += 1;
        }
    }

    return numberOfValidOffers;
};

export {processHotelInfo, getRequestParamsFromCookies, getNumberOfValidOffers, processFlightFromDestination, processFlightToDestination, countFirstLevelChildren};
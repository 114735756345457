import React, { useState } from "react";
import "./styles.css";

const CountryButton = ({ onClick, country }) => {
    const [isPressed, setIsPressed] = useState(false);

    const handleClick = () => {
        setIsPressed(!isPressed);
        onClick();
    };

    return (
        <div
            className={`button-country-outer rounded-button ${isPressed ? "pressed" : ""}`}
            onClick={handleClick}
        >
            <div className={`button-country-inner rounded-button ${isPressed ? "translate" : ""}`}>
                <p className={`country-city-letters ${isPressed ? "translate" : ""}`}>{country}</p>
            </div>
        </div>
    );
};

export default CountryButton;
